import React from "react"
import styled from "styled-components";
import ContainerSmall from "../../styles/ContainerSmall";


const TopWrapper = styled.div`
  border: solid 1px ${props => props.theme.blue};
  padding: 35px 50px;
  text-align: center;
  background: #fff;
  margin: 60px auto 0 auto;
  max-width: 70%;
  z-index: 20;
  position: relative;


  h2 {
    font-size: ${props => props.theme.fontLg};
    color: ${props => props.theme.blue};
    margin-top: 0;
    text-transform: uppercase;
  }
  p {
    font-size: ${props => props.theme.fontMdSm};
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const BottomWrapper = styled.div`
  border: solid 1px ${props => props.theme.blue};
  padding: 35px 50px;

  background: #ebebeb;
  margin-top: -110px;
  padding-top: 140px;
  h3 {
    font-size: ${props => props.theme.fontMd};
    color: ${props => props.theme.teal};
    text-transform: uppercase;
    margin-top: 3rem;
  }
  p,
  ul{
    font-size: ${props => props.theme.fontMdSm};
  }
`


const Timeline = () => {


  return(
    <ContainerSmall>
    <TopWrapper>
      <h2>Example of a Catalog Buildout Timeline</h2>
      <p><em>Total Implementation Timeline: 8 Weeks</em></p>
    </TopWrapper>
      <BottomWrapper>
        <h3>WEEK 2: SHELL SITE DELIVERED</h3>
        <p>We’ll send you a shell site with initial design, populated with several sample text pages and courses. At this point, you can review:</p>

        <ul>
          <li>Design/Layout</li>
            <li>Course search page functionality</li>
              <li>Accessibility</li>

          <li>Site search functionality</li>
          <li>Course information organization and design</li>
          <li>Individual PDFs for courses and text pages</li>
        </ul>

        <h3>Week 4: Courses Imported and Sample Degrees Populated</h3>
        <p>We’ll have all the courses imported and categorized in appropriate programs. At this point the import can be reviewed for accuracy, and we can create an admin account for any adjustments that need to be made to course content. We will also have several sample degrees populated so those templates can be reviewed, along with the degree search page.</p>

        <h3>Week 6: Sample PDF ready for review</h3>
        <p>We’ll have a sample version of the whole-catalog PDF ready for review (with sample degrees). At this point the PDF can be reviewed for design, organization, etc.</p>

        <h3>Week 7: Degrees Imported</h3>
        <p>We’ll have all the degrees imported to the site and ready for review.</p>
        <h3>Week 8: All user accounts set up and workflows completed</h3>
        <p>At this point we’ll have all user accounts set up, and any workflow setup will be com- pleted. At this point site will be fully built out and ready for launch, pending any content changes on the client end.</p>

      </BottomWrapper>


    </ContainerSmall>

  )
}

export default Timeline;
