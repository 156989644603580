import React from "react"
import styled from "styled-components";
import ContainerSmall from "../../styles/ContainerSmall";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const ReasonsWrapper = styled.div`
  a {
    color:  ${props => props.theme.blue};
  }
  h2 {
    font-size: ${props => props.theme.fontLg};
    color: ${props => props.theme.blue};
    text-align: center;
  }

  .gatsby-image-wrapper {
    width: 100%;
    z-index: -1;
  }
`

const ReasonsTextWrapper = styled(ContainerSmall)`
  box-sizing: border-box;
  padding: 70px;
  margin-top: -382px;
  background-color: #ebebeb;
  @media (max-width: ${props => props.theme.screenSmMax}) {
    margin-top: 0
  }
`

const ReasonsText = styled.div`
`

const Reason = styled.div`
  display: flex;
  margin-bottom: 30px;

  > span {
    font-size: ${props => props.theme.fontXl};
    color: ${props => props.theme.blue};
    display: inline-flex;
    margin-right: 45px;
  }

  > p {
    margin-top: 0;
    font-size: ${props => props.theme.fontMdSm};
  }

`

const Reasons = () => {


  return (
    <ReasonsWrapper>

      <StaticImage
        src={'../../../content/assets/buyersguide/reasons.png'}
        alt="University Catalog displayed on a laptop"
        fullWidth="FULL_WIDTH"
      />


      <ReasonsTextWrapper>
        <ReasonsText>
          <h2>3 REASONS TO CHOOSE
            CLEAN CATALOG SOFTWARE</h2>
        </ReasonsText>

        <Reason>
          <span>1</span>
          <p><strong><em>Your catalog will be more accessible and user-friendly.</em></strong> All of <Link to={'/features/accessibility/'}>our software is
            accessible out of the box</Link>, with no additional work required. For most of our clients, more than 50% of their catalog traffic comes from mobile devices, so having a
            mobile-friendly site makes your catalog accessible and usable for your students and potential students.
          </p>
        </Reason>

        <Reason>
          <span>2</span>
          <p><strong><em>Your staff will have a much easier time and will be empowered to create a better product.</em></strong> <Link to={'/features/workflows/'}>Customizable user workflows</Link>, granular permission settings, SSO capabilities, and templated content will make it easy to revise
            or publish new content to your catalog.</p>

        </Reason>
        <Reason>
          <span>3</span>
          <p><strong><em>The initial build-out is done for you.</em></strong> Once your team passes off your current catalog in whatever form it
            may be in, Clean Catalog does a complete migration at no additional cost. </p>
        </Reason>


      </ReasonsTextWrapper>

    </ReasonsWrapper>

  )
}

export default Reasons;
