import React from "react"
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import Container from "../../styles/Container";
import ContainerSmall from "../../styles/ContainerSmall";


const Wrapper = styled.div`
  padding: 30px;
  background: #fff;
  @media (min-width: ${props => props.theme.screenMd}) {
    padding: 270px 30px 150px 30px;
  }
  h2, h3 {
    text-align: center;
    margin-bottom: 0;
    margin-top: 3rem;
  }
`

const ImageWrapper = styled(Container)`
  text-align: center;
`


const Types = () => {

  return (
    <Wrapper>
      <ImageWrapper>
        <StaticImage
          src={'../../../content/assets/buyersguide/computerdesigns.png'}
          alt="University Catalogs displayed on a laptop"
          fullWidth={'FULL_WIDTH'}
        />
      </ImageWrapper>

      <ContainerSmall>

      <h2>Types of Software</h2>

      <p>We offer various software options to meet the needs of your institution. Our software options are typically the
      most affordable on the market. There is no initial start-up fee or hidden charges -- just one flat rate billed
        annually.</p>


      <h3>Course Catalog Software</h3>
      <p>Our Course Catalog Software transforms outdated PDF catalogs into digital catalogs that are accessible on any
      device. Content can be organized by courses, degrees, sub-degrees, emphasis areas, elective courses, or any unique
      structure that your institution uses. Our catalog sites are easy for students to navigate, staff to manage, and
      flexible enough to meet your institution’s unique needs.</p>


      <h3>Employee and Student Handbook Software</h3>
      <p>Employee handbooks and student handbooks are commonly integrated with course catalog or curriculum software. Our
      digital handbooks are accessible on any device and are print-ready with automatic PDF generation. We’ll do the
      heavy-lifting of migrating your handbook from its current format into a webpage, and work with your team to
      customize both the design and structure of the information.</p>


      <h3>Curriculum Management Software</h3>
      <p>We’ve simplified that process of managing your curriculum with our easy-to-use software. Your staff can utilize
      workflows, templated content, and user permissions to add new content, edit existing content, or submit content
      for approval. Our software has the capacity to connect to your SIS or any other system to help streamline content
      management.</p>


      <h3>Syllabus Management Software</h3>
      <p>Our Syllabus Management Software empowers your staff to create syllabi that are consistent across your institution
      and all departments. Custom templates ensure that no important information is omitted and that published content
        is always complete. All syllabi can be accessed both as a webpage and in PDF format.</p>
      </ContainerSmall>
    </Wrapper>

  )
}

export default Types;
