import React, {useState} from 'react';
import styled from "styled-components";
import ContainerSmall from "../../styles/ContainerSmall";
import Link from "gatsby-link";


const SwitchWrapper = styled(ContainerSmall)`
  margin-top: 80px;
  margin-bottom: 40px;

`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-direction: column;
  @media (min-width: ${props => props.theme.screenMd}) {
    flex-direction: row;
  }

  h2 {
    margin: 0;
    font-size: ${props => props.theme.fontLg};
    font-weight: 900;
    color: ${props => props.theme.blue};
    margin-right: 20px;
    line-height: 1.7;
  }
`

const SelectWrapper = styled.div`

  flex: 1 1 auto;
  display: inline-block;

  position: relative;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 13px solid ${props => props.theme.blue};

    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -5px;

  }


  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: none;
    border: none;
    font-size: 25px;
    font-style: italic;
    color: ${props => props.theme.blue};
    border-bottom: solid 3px ${props => props.theme.blue};
    padding-right: 40px;
    width: 100%;
    line-height: 1.7;

    &:hover {
      cursor: pointer;
    }
  }
`

const TextWrapper = styled.div`
  font-size: ${props => props.theme.fontMdSm};
  a {
    color:  ${props => props.theme.blue};
  }
`


const Switching = () => {

  const [open, setOpen] = useState('pdf');

  let text = (
    <div>
      <p>Migrating from a print catalog to a digital catalog is a gamechanger in terms of accessibility and content
        management. Today’s students will be accessing your academic catalog across various devices and screen sizes. A
        catalog with a responsive design will ensure that it is accessible on any device your students may use. Digital
        catalogs are also easier to navigate and manage than standard PDF catalogs. Clean Catalog software also comes
        with an automatic PDF generation tool, which allows you to print any page or the entire catalog on-demand.</p>

      <p>Switching to a digital catalog will also ease the burden of content management for your staff. PDF catalogs
        often require that revisions be delegated to one person who makes the changes within a Word Document or InDesign
        file before exporting a new catalog. This process can be tedious and often leads to multiple catalog versions
        circulating through campus. When you upgrade to a digital catalog, however, changes can be submitted by any user
        with the appropriate permissions. Even better, workflows can be customized to ensure that all content goes
        through the appropriate approval process.</p>

      <p>A digital catalog will also allow your institution to grow its online presence and community outreach through
        Search Engine Optimization (SEO). Clean Catalog software automatically adheres to SEO best practices, so your
        institutions will reap the benefits of increased online visibility without lifting a finger.</p>
    </div>
  );
  if (open === 'acalog') {
    text = (
      <div>
        <Link to={'/case-studies/sage-college/'}>Read Our Case Study on Switching to Clean Catalog from Acalog</Link>

        <p>For all current Acalog customers, we offer free migrations to Clean Catalog. We take care of everything, and
          you get a great product at a better price.</p>

        <p>We don't charge an up-front fee for the migration from Smart Catalog, and Clean Catalog's annual cost is typically less
          than
          Acalog's.</p>

        <p>For a comparison of features between Clean Catalog and Smart Catalog, visit <a
          href="https://coursecatalogsoftware.com/">our course catalog software comparison chart.</a></p>

      </div>
    );
  } else if (open === 'smart') {
    text = (
      <div>
        <Link to={'/case-studies/coastalpines-smart-catalog/'}>Read Our Case Study on Switching to Clean Catalog from
          Smart Catalog</Link>

        <p>For all current Smart Catalog customers, we offer free migrations to Clean Catalog. We take care of
          everything, and
          you get a great product at a better price.</p>

        <p>We don't charge an up-front fee for the migration from Smart Catalog, and Clean Catalog's annual cost is typically less
          than
          Smart Catalog's.</p>

        <p>For a comparison of features between Clean Catalog and Smart Catalog, visit <a
          href="https://coursecatalogsoftware.com/">our course catalog software comparison chart.</a></p>


      </div>
    );
  }


  return (


    <SwitchWrapper>

      <TopWrapper>
        <h2>Switching From</h2>
        <SelectWrapper>

          <select onChange={e => setOpen(e.target.value)} value={open}>
            <option value="pdf">a PDF or Word Document</option>
            <option value="acalog">Acalog</option>
            <option value="smart">Smart Catalog</option>
          </select>
        </SelectWrapper>
      </TopWrapper>

      <TextWrapper>
      {text}
      </TextWrapper>

    </SwitchWrapper>

  )
}

export default Switching;
