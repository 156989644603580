import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Timeline from "../components/buyersguide/Timeline";
import About from "../components/buyersguide/About";
import Reasons from "../components/buyersguide/Reasons";
import Switching from "../components/buyersguide/Switching";
import BuyerIntro from "../components/buyersguide/BuyerIntro";
import Fit from "../components/buyersguide/Fit";
import Pricing from "../components/buyersguide/Pricing";
import Start from "../components/buyersguide/Start";
import CaseStudies from "../components/buyersguide/CaseStudies";
import Types from "../components/buyersguide/Types";
import Customizing from "../components/buyersguide/Customizing";
import SoftwareFeatures from "../components/buyersguide/SoftwareFeatures";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";


const FullWidthImage = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
  }
`


export default function BuyersGuide() {


  return (
    <Layout grayFooter={true}>


      <BuyerIntro
        title={'Buyer\'s Guide '}
        text={'        <h2>A Comprehensive Guide to Buying Clean Catalog Software</h2>\n' +
          '        <p>We provide customizable academic software designed to address the unique needs of each institution we work with. Our solutions include:</p>\n' +
          '        <ul>\n' +
          '          <li>Course Catalog Software</li>\n' +
          '          <li>Curriculum Management Software</li>\n' +
          '          <li>Syllabus Management Software</li>\n' +
          '          <li>Student Handbook Software</li>\n' +
          '          <li>Employee Handbook Software</li>\n' +
          '        </ul>'}

      />

      <Fit/>
      <Pricing/>

      <Start
        title={'WHAT IT LOOKS LIKE TO GET STARTED WITH CLEAN CATALOG'}
        text={'All the steps, from initial contact to having a fully-functioning catalog.'}
        stepOneTitle={'Initial Contact'}
        stepOneText={'            <p>To get started, send us a note! You can <Link to={\'/contact/\'}>contact us here</Link>, email <a href=\'mailto:sales@cleancatalog.com\'>sales@cleancatalog.com</a>, or call us at\n' +
          '              1-800-648-7146.</p>\n' +
          '            <p>We typically either email with you a bit or schedule a quick call with the goal of figuring out your\n' +
          '              needs so we can put together a pricing proposal for you and schedule a software demo.</p>'}
        stepTwoTitle={'Pricing Proposal'}
        stepTwoText={'   <p>Once we’ve talked with you about your needs, we’ll provide a detailed pricing proposal along with a\n' +
          '              high-level view of services provided. These are typically one-page documents you can easily share with\n' +
          '              other stakeholders to give a solid idea of what you’d be paying and what you’d be getting.</p>'}
        stepThreeTitle={'Software Demos'}
        stepThreeText={'           <p>We’ll schedule an hour-long Zoom demo where we’ll discuss your specific needs and pain points and then\n' +
          '              give you a tour of how our software can help solve those problems. We allow plenty of time during demos\n' +
          '              for questions and discussion to make sure you get all the information you need for how Clean Catalog can\n' +
          '              meet your needs.</p>\n' +
          '\n' +
          '            <p>We know that catalog and curriculum often have a lot of stakeholders, so we’re happy to schedule\n' +
          '              further demos for specific groups. Sometimes we’ll schedule demos with just the curriculum committee to\n' +
          '              go over curriculum management processes in detail; other times we’ll schedule a technical demo to go\n' +
          '              over API integration, SSO, and other technical aspects.</p>'}
        stepFourTitle={'Purchasing — RFP Process, Contracts, and/or Invoicing'}
        stepFourText={'            <p>We know how to work with purchasing departments! Whether we’re going through a formal RFP process or\n' +
          '              getting set up in a vendor payment system, we’ll work with your team to make sure everything gets\n' +
          '              approved and set to go.</p>\n' +
          '\n' +
          '            <p>We typically do year-to-year contracts without a lot of additional restrictions, penalties, or anything\n' +
          '              like that. We want to earn your renewal every year (and we pretty much always do!), so we don’t lock\n' +
          '              users into long-term contracts (unless that’s preferred on their end).</p>\n' +
          '\n' +
          '            <p> We know that sometimes it can take a bit to get purchasing set up, so we often get going on the\n' +
          '              buildout process before payment is completed.</p>\n'}

      />

      <Timeline/>
      <About/>
      <Reasons/>
      <Switching/>
      <CaseStudies/>

      <Types/>


      <Customizing/>
      <SoftwareFeatures/>


      <FullWidthImage>
        <StaticImage
          src={'../../content/assets/buyersguide/phones.png'}
          alt="course catalogs displayed on tablets"
          fullWidth={'FULL_WIDTH'}
        />
      </FullWidthImage>


    </Layout>
  )

}

export const Head = (props) => {
  return (
    <Seo
      title="Buyer's Guide"
      description='Guide to purchasing course catalog software from Clean Catalog.'
      canonical={props.location.pathname}
    />
  )
};



