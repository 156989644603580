import React from "react"
import styled from "styled-components";

import ContainerSmall from "../../styles/ContainerSmall";
import {StaticImage} from "gatsby-plugin-image";




const SectionTwo = styled(ContainerSmall)`
  display: flex;
  flex-direction: column;

  > div {
    padding: 30px;
    @media (min-width: ${props => props.theme.screenMd}) {
      padding: 60px 60px 20px 60px;
    }

    h2 {
      font-weight: 900;
      font-size: ${props => props.theme.fontXl};
      color: ${props => props.theme.blue};
      text-align: center;
    }

    p, ul {
      font-size: ${props => props.theme.fontMdSm};
    }


    li:not(:last-child) {
      padding-bottom: .75em;
    }


  }

  > div:first-child {
    ul {
      @media (min-width: ${props => props.theme.screenMd}) {
        column-count: 2;
      }
    }
  }
`

const Fit = () => {


  return (
    <SectionTwo>
      <div>
        <h2>WHO ARE WE A GOOD FIT FOR?</h2>
        <p>We pride ourselves on having software that’s flexible enough to meet a variety of needs, and colleges and
          universities of all sizes, non-profits, secondary education organizations, and more have all put our software to good
          work. The list below comprises most of our customer base:</p>

        <ul>
          <li>Community, technical, and career-training colleges</li>
          <li>Private liberal arts colleges</li>
          <li>HBCUs</li>
          <li>Primary/secondary school districts</li>
          <li>Nonprofits and government agencies that offer courses</li>
          <li>Large public universities</li>
        </ul>
      </div>
      <div>
        <h2>WHO ARE WE NOT A GOOD FIT FOR?</h2>
        <p><em>We want all of our partners to be successful, and in some of the cases below we might not be the best
          fit — but we’re always happy to talk it over.</em></p>

        <ul>
          <li><strong><em>Colleges/universities looking for an all-in-one-box, full-campus solution for all their IT needs.</em></strong> We
            focus on offering the best catalog, curriculum, and syllabus software. If you’re looking for an all-in-one
            SIS, LMS, CRM, or any other fun three-letter software, that’s not us! But our system is very easy to
            integrate with through our easy-to-use API.
          </li>

          <li><strong><em>Organizations looking to work with large legacy vendors.</em></strong> We’re a smaller company, and our customers love
            us. We have an extremely stable business model, a loyal customer base, and plans to be around a long time.
            That said, some organizations prefer to work with larger, legacy providers. No hard feelings, but that’s
            not us.
          </li>
        </ul>
      </div>


      <StaticImage
        src={'../../../content/assets/buyersguide/cellphones.png'}
        alt="iPhones displaying course catalog software"

      />
    </SectionTwo>
  )
}

export default Fit;
