import React from "react"
import styled from "styled-components";
import ContainerSmall from "../../styles/ContainerSmall";
import {StaticImage} from "gatsby-plugin-image";


const AboutWrapper = styled.div`
  background-color: ${props => props.theme.blue};
  padding: 30px;
  margin-top: 135px;

  position: relative;
  @media (min-width: ${props => props.theme.screenMd}) {
    padding: 200px 30px;
  }

  .gatsby-image-wrapper {
    display: none;

    @media (min-width: ${props => props.theme.screenMd}) {
      display: block;
      max-width: 75vw;
      position: absolute;
      right: 0;
      top: 180px;

    }
  }
`

const AboutInterior = styled(ContainerSmall)`
  display: flex;

  > div {
    @media (min-width: ${props => props.theme.screenMd}) {
      width: 50%;
    }
  }
`

const AboutLeft = styled.div`
  color: #fff;

  h2 {
    text-transform: uppercase;
    font-size: ${props => props.theme.fontXl};
  }

  p {
    font-size: ${props => props.theme.fontMdSm};
    margin-bottom: 60px;
  }
`


const About = () => {


  return (
    <AboutWrapper>
      <AboutInterior>

        <AboutLeft>
          <h2>About Clean Catalog</h2>
          <p>Clean Catalog was founded in 2015 with the goal of helping colleges and universities publish streamlined,
            accessible, and easy-to-manage digital publications. We believe that you shouldn’t need the budget of a
            large
            public university to afford great academic software that both your students and staff enjoy using.</p>


          <p> We’re a for-profit LLC, but we try to be a good player in the education space. We offer four annual $500
            scholarships to students based on financial need. Like most of our clients, we prioritize accessibility and
            access — we want to ensure that all students and potential students can access the information they need to
            pursue their education.</p>

        </AboutLeft>


      </AboutInterior>

      <StaticImage
        src={'../../../content/assets/buyersguide/macbook.png'}
        alt="University Catalog displayed on a laptop"
        width={1800}


      />

    </AboutWrapper>

  )
}

export default About;
