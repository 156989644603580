import ContainerSmall from "../../styles/ContainerSmall";
import React from "react";
import styled from "styled-components";



const GetStarted = styled.div`
  padding-top: 110px;
  a {
    color:  ${props => props.theme.blue};
  }
`


const GetStartedItems = styled.div`
  display: grid;

  grid-gap: 60px;
  padding: 30px;

  @media (min-width: ${props => props.theme.screenMd}) {
    grid-template-columns: 50% 50%;
  }
  > div {
    > h3 {
      text-align: center;
      font-size: ${props => props.theme.fontMd};
    }

  }
`

const GetStartedNumber = styled.span`
  font-size: 54px;
  background-color: ${props => props.theme.blue};
  border-radius: 1.5em;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: #fff;
`

const TopContainerSmall = styled(ContainerSmall)`
  padding: 30px;

  h2 {
    color: ${props => props.theme.blue};
    text-align: center;
    font-size: ${props => props.theme.fontLg};
    font-weight: bold;
    margin-top: 0;
  }

  p {
    text-align: center;
    font-size: ${props => props.theme.fontMd};
  }
`




const Start = (props) => {


  return (
    <GetStarted>
      <TopContainerSmall>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
      </TopContainerSmall>
      <ContainerSmall>
        <GetStartedItems>
          <div>
            <GetStartedNumber>
              1
            </GetStartedNumber>
            <h3>{props.stepOneTitle}</h3>
            <div dangerouslySetInnerHTML={{__html: props.stepOneText}} />

          </div>
          <div>
            <GetStartedNumber>
              2
            </GetStartedNumber>

            <h3>{props.stepTwoTitle}</h3>
            <div dangerouslySetInnerHTML={{__html: props.stepTwoText}} />

          </div>
          <div>
            <GetStartedNumber>
              3
            </GetStartedNumber>

            <h3>{props.stepThreeTitle}</h3>
            <div dangerouslySetInnerHTML={{__html: props.stepThreeText}} />


          </div>


          {props.stepFourTitle && props.stepFourTitle.length > 1 &&

            <div>
              <GetStartedNumber>
                4
              </GetStartedNumber>

              <h3>{props.stepFourTitle}</h3>
              <div dangerouslySetInnerHTML={{__html: props.stepFourText}}/>


            </div>
          }

        </GetStartedItems>
      </ContainerSmall>
    </GetStarted>
  )
};


export default Start;
