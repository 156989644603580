import {graphql, useStaticQuery} from "gatsby";
import React from "react"
import styled from "styled-components";


const SectionHeader = styled.div`
  background-color: ${props => props.theme.blue};
  text-align: center;
  position: relative;

  &:after {
    content: "Software Features";
    opacity: .05;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 8.7vw;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
    font-weight: 900;

  }

  h2 {
    margin: 0;
    padding: 90px 0;
    text-transform: uppercase;
    color: #fff;
  }
`

const ItemsWrapper = styled.div`
  @media (min-width: ${props => props.theme.screenSm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
`;

const ItemWrapper = styled.div`
  padding: 40px;
  display: flex;
  justify-content: flex-start;
  background-color: #ebebeb;

  @media (max-width: ${props => props.theme.screenSmMax}) {
    &:nth-child(odd) {
      background-color: ${props => props.theme.teal};
      color: #fff;
    }
  }

  @media (min-width: ${props => props.theme.screenSm}) {
    &:nth-child(odd) {
      justify-content: flex-end;
    }

    &:nth-child(1),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(8) {
      background-color: ${props => props.theme.teal};
      color: #fff;
    }
  }

  > div {
    max-width: 500px;
    width: 100%;
  }

  h3 {
    text-transform: uppercase;
  }
`;


const SoftwareFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "feature"}}}) {
        edges {
          node {
            frontmatter {
              description
              path
              tag
              teaser
              vimeoid
              title
            }
          }
        }
      }
    }
  `);


  let features = data.allMarkdownRemark.edges.map((item) => {

    // Exclude a couple
    if (['syllabus'].includes(item.node.frontmatter.tag)) {
      return null;
    }


    return (
      <ItemWrapper>
        <div>
          <h3>{item.node.frontmatter.title}</h3>
          <p>{item.node.frontmatter.description}</p>

        </div>
      </ItemWrapper>
    )

      ;
  });


  return (
    <div>
      <SectionHeader>
        <h2>Software Features</h2>
      </SectionHeader>
      <ItemsWrapper>
        {features}
      </ItemsWrapper>
    </div>
  )
}

export default SoftwareFeatures;
