import React from "react"
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";


const BuyerIntroWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 0;
    font-size: ${props => props.theme.fontLg};
  }

  @media (min-width: ${props => props.theme.screenMd}) {
    flex-direction: row;
  }

  > div:first-child {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,.6);
      z-index: 10;
    }
    @media (min-width: ${props => props.theme.screenMd}) {
      width: 50%;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      max-height: 50vh;
      @media (min-width: ${props => props.theme.screenMd}) {
        max-height: 299vh;
      }
    }
  }

  > div:nth-child(2) {
    background-color: ${props => props.theme.teal};
    font-size: ${props => props.theme.fontMdSm};
    color: #fff;
    padding: 40px;
    box-sizing: border-box;
    @media (min-width: ${props => props.theme.screenMd}) {
      width: 50%;
      padding: 190px 60px;
    }
  }

`
const HeaderH1 = styled.h1`
  color: #fff;
  font-size: 5rem;
  opacity: .58;
  text-transform: uppercase;
  position: absolute;
  top: 50px;
  right: 55px;
  margin-top: 0;
  line-height: 1;
  text-align: right;
  z-index: 20;
  @media (min-width: ${props => props.theme.screenMd}) {
    top: 190px;
  }

`;


const BuyerIntro = (props) => {


  return (
    <BuyerIntroWrapper>
      <div>
        <StaticImage
          src={'../../../content/assets/buyersguide/buyers-guide.jpg'}
          alt="Student using course Catalog Software"
          placeholder="blurred"

          imgStyle={{
            objectFit: "cover",
            objectPosition: "50% 50%",
          }}
        />

        <HeaderH1>{props.title}</HeaderH1>

      </div>
      <div dangerouslySetInnerHTML={{__html: props.text}} />

    </BuyerIntroWrapper>

  )
}

export default BuyerIntro;
