import React from "react"
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import ContainerSmall from "../../styles/ContainerSmall";




const Wrapper = styled.div`
  padding: 30px;
  position: relative;
  @media (min-width: ${props => props.theme.screenMd}) {
    padding: 150px 30px;
  }
`

const Text = styled.div`
  @media (min-width: ${props => props.theme.screenMd}) {
    max-width: 600px;
    margin-left: 30%;
  }
`




const ImageWrapper = styled.div`



  @media (min-width: ${props => props.theme.screenMd}) {
    display: block;
    width: 30%;
    margin-right: 40px;
    position: absolute;
    left: 0;
    top: 80px;
  }
`

const Customizing = () => {

  return (
    <div>
    <Wrapper>
      <ImageWrapper>
        <StaticImage
          src={'../../../content/assets/buyersguide/customizing.png'}
          alt="course catalogs displayed on tablets"
          width={600}
        />
      </ImageWrapper>
      <ContainerSmall>

        <Text>
          <h2>CUSTOMIZING YOUR CONTENT</h2>
          <p>Clean Catalog software has countless options for customizing your content. Our team will work with you to
            customize the design, functionality, and organization of your digital catalogs. Here are a few ways our
            clients have customized their catalogs:</p>

          <ul>
            <li>Integrating their institution’s branding, including colors, graphics, photos, and logos, into the site
              design
            </li>
            <li>Embedding emphasis area sub-degrees within each degree page</li>
            <li>Creating designs in Photoshop for us to integrate into their site design</li>
            <li>Displaying unique information on course pages, such as a different type of credit or classification
              structure
            </li>
            <li>Creating Curriculum Guide Templates to provide a seamless way for staff to add in-depth information
              about
              degrees
            </li>
            <li>Designing a custom cover page for the PDF version of their catalog</li>
          </ul>


          <p>If your team has a unique request or particular vision for your catalog, our team is always willing to make
            those happen.</p>

        </Text>
      </ContainerSmall>



    </Wrapper>

    </div>

  )
}

export default Customizing;
