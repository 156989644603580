import React from "react"
import styled from "styled-components";
import Link from "gatsby-link";


const Wrapper = styled.div`
  padding: 80px 30px;
  background-color: #d2d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    text-align: right;
    font-size: ${props => props.theme.fontXl};
    color: #67757f;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    li {
      &:not(:last-of-type) {
        padding-bottom: .5rem;
      }
    }
    a {
      color: ${props => props.theme.blue};
      font-size: ${props => props.theme.fontMdSm};
    }
  }
`


const CaseStudies = () => {


  return (
    <Wrapper>

      <h2>Case<br />Studies</h2>
      <ul>
        <li><Link to={'/case-studies/villanova/'}>Villanova University</Link></li>
        <li><Link to={'/case-studies/weatherford/'}>Weatherford College</Link></li>
        <li><Link to={'/case-studies/bristol-community-college/'}>Bristol Community College</Link></li>
        <li><Link to={'/case-studies/acps-case-study/'}>Alexandria City Public Schools</Link></li>
      </ul>

    </Wrapper>

  )
}

export default CaseStudies;
