import React from "react"
import styled from "styled-components";

import ContainerSmall from "../../styles/ContainerSmall";
import {Link} from "gatsby";




const PricingWrapper = styled.div`
  background-color: ${props => props.theme.blue};
  margin-top: -130px;
  padding-top: 200px;
  padding-bottom: 70px;
  padding-right: 30px;
  padding-left: 30px;
  @media (min-width: ${props => props.theme.screenMd}) {
    margin-top: -250px;
    padding-top: 340px;
    padding-bottom: 140px;
  }

  h2 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: ${props => props.theme.fontXl};
    color: #fff;
    text-transform: uppercase;
  }

  p {
    color: #fff;
    font-size: ${props => props.theme.fontMdSm};

    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: #fff;
  }
`

const Pricing = () => {


  return (
    <PricingWrapper>
      <ContainerSmall>
        <h2>Pricing</h2>
        <p>One of our goals is to provide affordable, best-in-class software for colleges and universities of all sizes. We are almost always the most affordable option for catalog, curriculum,
          and syllabus management software — and clients who’ve switched from other providers tell us that they’re
          paying less for a better product.</p>

        <p>We don’t do one-size-fits-all pricing, so we can’t give a full pricing table here. We can tell you that
          colleges with as few as 600 FTE students are able to afford our software, and that we make it a point to
          ensure we can provide software that’s affordable for most small colleges. We’re happy to get a quote for
          your institution if you email <a href="mailto:sales@cleancatalog.com">sales@cleancatalog.com</a>, even if you’re just curious and not ready to buy
          anything yet.</p>

        <p><Link to={'/blog/no-startup-fee/'}>We don’t typically charge startup fees for software</Link>, even though <Link to={'/blog/buildout/'}>we take care of buildout</Link> and hand over
          fully populated, fully functioning software.</p>
      </ContainerSmall>
    </PricingWrapper>
  )
}

export default Pricing;
